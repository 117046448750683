import { render, staticRenderFns } from "./LeagueRules.vue?vue&type=template&id=7ad5df64&scoped=true&"
import script from "./LeagueRules.vue?vue&type=script&lang=js&"
export * from "./LeagueRules.vue?vue&type=script&lang=js&"
import style0 from "./LeagueRules.vue?vue&type=style&index=0&id=7ad5df64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad5df64",
  null
  
)

export default component.exports