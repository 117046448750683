<template>
  <div class="container">
    <div class="row align-items-center profile-header">
      <div class="col-md text-center">
        <h2>Unauthorized</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "unathorized"
};
</script>
